/**
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

.data-table th {
    cursor: pointer;
    user-select: none;
}

.data-table .hidden {
    opacity: 0;
}