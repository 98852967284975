/*
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

 .center-screen {

    position        : absolute;
    left            : 0;
    right           : 0;
    top             : 0;
    bottom          : 0;

    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;

}