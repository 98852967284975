/*
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

main {
    position        : absolute;
    top             : 0;
    right           : 0;
    left            : 0;
    padding-top     : 4.5rem;
    padding-bottom  : 1rem;
    min-height      : 100vh;
    min-height      : -webkit-fill-available;
}