/**
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

.carousel {
    font-size       : 12pt;
    position        : relative;
}

.carousel .prev, .carousel .next {
    position        : absolute;
    top             : 0;
    bottom          : 0;
    width           : 2rem;
    display         : flex;
    flex-direction  : row;
    align-items     : center;
    justify-content : center;
    cursor          : pointer;
    user-select     : none;
    font-size       : 24pt;
    color           : #23a2c9;
}

.carousel .prev {
    left            : 0;
}

.carousel .next {
    right           : 0;
}

.carousel .contents {
    position        : absolute;
    left            : 2rem;
    right           : 2rem;
    top             : 0;
    bottom          : 0;
    overflow        : hidden;
}

.carousel .contents .scroller {
    display         : flex;
    flex-direction  : row;
    align-items     : stretch;
    position        : absolute;
    left            : -30px;
    right           : 0;
    top             : 0;
    bottom          : 0;
    overflow        : hidden;
}

.carousel .contents .scroller .item {
    border-radius   : 0.2rem;
    color           : #666;
    margin          : 0 0.25rem;
    padding         : 0 0.75rem;
    white-space     : nowrap;
    user-select     : none;
    cursor          : pointer;
    display         : flex;
    flex-direction  : row;
    align-items     : center;
}

.carousel .contents .scroller .item.active {
    background-image: linear-gradient(#4493ac, #2182a0 40%, #155264);
    color           : #fff;
    text-shadow     : 1px 1px 1px rgba(0, 0, 0, 0.6);
}