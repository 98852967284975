/**
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

html, body {
    background-color: #131518;
    font-family     : 'Roboto', sans-serif;
    overflow-y      : hidden;
}

#wallpaper {
    position        : fixed;
    top             : 0;
    right           : 0;
    left            : 0;
    height          : 100vh;
    opacity         : 0.25;
    background      : #000 url('images/david_william_hearn_-588-1728x1152.jpg') center/cover no-repeat;
}

#root {
    position        : fixed;
    top             : 0;
    right           : 0;
    left            : 0;
    bottom          : 0;
    overflow-y      : auto;
}

.bg-danger {
    background-color: #800 !important;
}

.btn {
    padding         : 0.5rem 1rem;
}

.btn-bare {
    border          : none;
    background      : transparent;
}

.btn-info, .bg-info {
    background-image: linear-gradient(#4493ac, #2182a0 40%, #155264);
}

.btn-info:disabled {
    color           : #bbb;
    background-image: linear-gradient(#78868a, #7d888b 40%, #3c4142);
    border          : 1px solid #666;
    opacity         : 1;
    cursor          : default;
}

.custom-select {
    height          : auto;
    padding         : 0.5rem 1rem;
}

.form-control {
    height          : auto;
    padding         : 0.5rem 0.5rem;
}

.form-control {
    box-shadow      : none;
    outline         : none;
    border          : 2px solid #7a8288;
    color           : #000;
}

.input-group-prepend span, .input-group-append span {
    border          : none;
}

.input-group-text {
    color           : #fff !important;
    padding         : 0.5rem 1rem;
    text-shadow     : 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
}

input[type='checkbox'] {
    width           : 1.25rem;
    height          : 1.25rem;
    margin-right    : 0.5rem;
}

input::placeholder {
    font-size       : 9pt;
    font-style      : italic;
}

.table th {
    color           : #666;
    font-size       : 11pt;
    text-transform  : uppercase;
}

table.borderless {
    margin-left     : -0.3rem;
    margin-right    : -0.3rem;
}

table.borderless td, table.borderless th {
    border          : none;
}

.text-medium {
    font-size       : 18pt;
}

.text-large {
    font-size       : 22pt;
}