@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
/*
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

 .center-screen {

    position        : absolute;
    left            : 0;
    right           : 0;
    top             : 0;
    bottom          : 0;

    display         : flex;
    flex-direction  : column;
    align-items     : center;
    justify-content : center;

}
/**
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

.link {
    cursor: pointer;
}

.link-disabled {
    cursor: default;
}
/**
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

.navbar {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
}

.navbar-brand {
    border-right: none;
    padding-left: 0;
}

.navbar-brand img {
    height: 2.5rem;
}

.navbar-link {
    border-right: 1px solid #3c3c40;
    padding: 0.75rem 0.5rem;
}

.navbar-link.active {
    color: #fff;
}
.navbar .dropdown-icon {
    width: 2.25rem !important;
}

.navbar .dropdown-item {
    font-weight: bold;
}
/*
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

main {
    position        : absolute;
    top             : 0;
    right           : 0;
    left            : 0;
    padding-top     : 4.5rem;
    padding-bottom  : 1rem;
    min-height      : 100vh;
    min-height      : -webkit-fill-available;
}
/**
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

.data-table th {
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.data-table .hidden {
    opacity: 0;
}
/**
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

.carousel {
    font-size       : 12pt;
    position        : relative;
}

.carousel .prev, .carousel .next {
    position        : absolute;
    top             : 0;
    bottom          : 0;
    width           : 2rem;
    display         : flex;
    flex-direction  : row;
    align-items     : center;
    justify-content : center;
    cursor          : pointer;
    -webkit-user-select     : none;
       -moz-user-select     : none;
        -ms-user-select     : none;
            user-select     : none;
    font-size       : 24pt;
    color           : #23a2c9;
}

.carousel .prev {
    left            : 0;
}

.carousel .next {
    right           : 0;
}

.carousel .contents {
    position        : absolute;
    left            : 2rem;
    right           : 2rem;
    top             : 0;
    bottom          : 0;
    overflow        : hidden;
}

.carousel .contents .scroller {
    display         : flex;
    flex-direction  : row;
    align-items     : stretch;
    position        : absolute;
    left            : -30px;
    right           : 0;
    top             : 0;
    bottom          : 0;
    overflow        : hidden;
}

.carousel .contents .scroller .item {
    border-radius   : 0.2rem;
    color           : #666;
    margin          : 0 0.25rem;
    padding         : 0 0.75rem;
    white-space     : nowrap;
    -webkit-user-select     : none;
       -moz-user-select     : none;
        -ms-user-select     : none;
            user-select     : none;
    cursor          : pointer;
    display         : flex;
    flex-direction  : row;
    align-items     : center;
}

.carousel .contents .scroller .item.active {
    background-image: linear-gradient(#4493ac, #2182a0 40%, #155264);
    color           : #fff;
    text-shadow     : 1px 1px 1px rgba(0, 0, 0, 0.6);
}
/**
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

html, body {
    background-color: #131518;
    font-family     : 'Roboto', sans-serif;
    overflow-y      : hidden;
}

#wallpaper {
    position        : fixed;
    top             : 0;
    right           : 0;
    left            : 0;
    height          : 100vh;
    opacity         : 0.25;
    background      : #000 url(/static/media/david_william_hearn_-588-1728x1152.2eb1a8c4.jpg) center/cover no-repeat;
}

#root {
    position        : fixed;
    top             : 0;
    right           : 0;
    left            : 0;
    bottom          : 0;
    overflow-y      : auto;
}

.bg-danger {
    background-color: #800 !important;
}

.btn {
    padding         : 0.5rem 1rem;
}

.btn-bare {
    border          : none;
    background      : transparent;
}

.btn-info, .bg-info {
    background-image: linear-gradient(#4493ac, #2182a0 40%, #155264);
}

.btn-info:disabled {
    color           : #bbb;
    background-image: linear-gradient(#78868a, #7d888b 40%, #3c4142);
    border          : 1px solid #666;
    opacity         : 1;
    cursor          : default;
}

.custom-select {
    height          : auto;
    padding         : 0.5rem 1rem;
}

.form-control {
    height          : auto;
    padding         : 0.5rem 0.5rem;
}

.form-control {
    box-shadow      : none;
    outline         : none;
    border          : 2px solid #7a8288;
    color           : #000;
}

.input-group-prepend span, .input-group-append span {
    border          : none;
}

.input-group-text {
    color           : #fff !important;
    padding         : 0.5rem 1rem;
    text-shadow     : 1px 1px 1px rgba(0, 0, 0, 0.3) !important;
}

input[type='checkbox'] {
    width           : 1.25rem;
    height          : 1.25rem;
    margin-right    : 0.5rem;
}

input::-webkit-input-placeholder {
    font-size       : 9pt;
    font-style      : italic;
}

input:-ms-input-placeholder {
    font-size       : 9pt;
    font-style      : italic;
}

input::-ms-input-placeholder {
    font-size       : 9pt;
    font-style      : italic;
}

input::placeholder {
    font-size       : 9pt;
    font-style      : italic;
}

.table th {
    color           : #666;
    font-size       : 11pt;
    text-transform  : uppercase;
}

table.borderless {
    margin-left     : -0.3rem;
    margin-right    : -0.3rem;
}

table.borderless td, table.borderless th {
    border          : none;
}

.text-medium {
    font-size       : 18pt;
}

.text-large {
    font-size       : 22pt;
}
