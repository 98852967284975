/**
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

.navbar {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid black;
}

.navbar-brand {
    border-right: none;
    padding-left: 0;
}

.navbar-brand img {
    height: 2.5rem;
}

.navbar-link {
    border-right: 1px solid #3c3c40;
    padding: 0.75rem 0.5rem;
}

.navbar-link.active {
    color: #fff;
}
.navbar .dropdown-icon {
    width: 2.25rem !important;
}

.navbar .dropdown-item {
    font-weight: bold;
}