/**
 * Copyright (C) 2020 StaffPad Ltd. All rights reserved.
 */

.link {
    cursor: pointer;
}

.link-disabled {
    cursor: default;
}